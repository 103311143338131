:root {
  --red: #EB455F;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main {
  width: 100vw;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  height: 10vh;
  width: 100vw;
}
footer p {text-align: center;}

/*///////////////////////////////////set background for all pages*/
.bg {
  background-image: url(./imgs/IMG_1274_1920x1394.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*///////////////////////////////////navbar*/
nav {
  position: fixed;
  height: 10vh;
  width: 100vw;
}
nav img{
  position: fixed;
  right: 0;
  top: 0;
} 
nav h2 {
  color: black;
  font-family: 'Dosis', sans-serif;
}
#mobileMenuIcon {
  font-size: 2.5rem;
  margin-top: 0.5rem;
  color: var(--red);
}

/*///////////////////////////////////texts*/
h1, h2, h3, h5, h6, p {
  margin: 0;
  padding: 0;
}
h5, h6 {font-family: poppins;}
h5 {font-size: 1.2rem;}
h6 {font-size: 1rem;}

/*///////////////////////////////////dashboard*/
#dashboard h1{text-align: center;}
#dashboard h3{text-align: center;}
#dashboard p{text-align: center;}
#dashboardContent div:nth-child(1) p {padding: 0 0 0.5rem;}
#dashboardContent div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

/*///////////////////////////////////lesson plans & rotation*/
#disableText{
  -webkit-user-select:none !important;
  -webkit-touch-callout:none !important;
  -moz-user-select:none !important;
  -ms-user-select:none !important;
  user-select:none !important;   
}
.MuiTableCell-root {border: 1px solid;}


/*////////////////////////////////////signin*/
#signin {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 1rem;
}
#signin .MuiButton-root{height: 3rem;}

/*////////////////////////////////////signin*/
#attendance h1 {text-align: center;}

/*////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////////////media queries*/
/*//////////////////////////////////////////////desktop-view*/
@media only screen and (min-width: 768px) {
  nav img {width: 15vw;}
  nav h2 {font-size: 1.2rem;}
  #dashboard {margin-top: 5vh;}
  #tab {
    display: flex;
    justify-content: center;
    width: 60vw;
  }
  #tab .MuiButtonBase-root {margin: auto;}
}


/*////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////////////media queries*/
/*///////////////////////////////////////////////mobile-view*/
@media only screen and (max-width: 767px) {
  /*////////////////////////////////////navbar*/
  nav div:nth-child(1) {
    max-width: 70vw;
    display: flex; 
    flex-shrink: 2;
    align-content: flex-start;
  }
  nav .MuiButton-root {
    margin: 0;
    padding: 0;
  }
  nav img {width: 25vw;}
  #drawerMenu h2 {
    padding: 0.25rem;
    margin: 0;
    font-size: 1rem;
  }

  /*////////////////////////////////////dashboard*/
  #dashboard {width: 70vw;}
  
  /*////////////////////////////////////certificate*/
  #certificate .MuiTableCell-root {padding: 0.5rem 0;}
}

