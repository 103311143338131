:root{--darkblue: #354259;}

.maxWidthMinHeight {
  min-height: 90vh;
  max-width: 100vw;
}
.theGrid {display: grid;}
.centerHorizontally {
  display: flex;
  justify-content: center;
}
.centerVertically {
  display: flex;
  align-items: center;
}
.flexVertically {
  display: flex;
  flex-direction: column;
}
.flexHorizontally {
  display: flex;
  flex-direction: row;
}
.gap {
  display: flex;
  gap: 0.25rem;
}
.box {
  background-image: url(./imgs/white-transparent.png);
  background-size: cover;
  width: fit-content;
  padding: 0.5rem 1rem 2rem;
  border-radius: 0.25rem;
}
.boxForLessonPlan {
  background-image: url(./imgs/white-transparent-lp.png);
  background-size: cover;
  width: fit-content;
  padding: 2rem;
  border-radius: 0.25rem;
}
